
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
import Card from '@/components/Card.vue'
import CardContainer from '@/components/CardContainer.vue'
import useVideos from '@/models/list/videos'
import useSession from '@/models/get/session'
import useSessions from '@/models/list/sessions'
import useUnits from '@/models/list/units'
import { AiSession, AiSessionType } from '@/api/sessions'
import { AiVideo } from '@/api/videos'
import { api } from '@/main'

export default defineComponent({
  name: 'VideoSele',
  components: {
    CardContainer,
    Card,
  },
  props: {
    sessionId: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const review = ref('')
    const { fetchItems: fetchLeft, items: itemsLeft } = useVideos()
    const { fetchItems: fetchRight, items: itemsRight } = useVideos()
    const { fetchItem: fetchSession, item: session } = useSession()
    const { fetchItems: fetchSessions, items: foundSessions } = useSessions()
    const { fetchItems: fetchUnits, items: units } = useUnits()
    const sessionB = ref<AiSession>()

    const checked = ref<string[]>([])
    const startframe = ref<number[]>([])
    const endframe = ref<number[]>([])
    const unitCnt = ref(0)
    const colorMap = new Map()

    function onCheck(video: AiVideo) { // function for checkbox
      startframe.value.push(Number(video?.startFrame ?? -1))
      endframe.value.push(Number(video?.startFrame ?? -1) + Number(video?.frameCount ?? -1) - 1)
    }

    async function onSubmit() { // when submit, create a unit for the group
      const unit = {
        videoSegments: checked.value,
        startFrame: String(Math.min(...startframe.value)),
        endFrame: String(Math.max(...endframe.value)),
        procedure: session.value.procedure,
      }
      const inputs = document.getElementsByTagName('input')
      for (const i in inputs) { // clean the previous unit checkbox
        if (inputs[i].type === 'checkbox') {
          inputs[i].checked = false
        }
      }
      const [, sessionBId] = (sessionB.value?.name ?? '').split('/')
      api.units.unitsCreateUnit({ session: sessionBId, body: unit }) // create unit
      unitCnt.value += 1
      startframe.value = [] // clean all the data after submit
      endframe.value = []
      checked.value = []
      fetchUnits({ session: sessionBId })
    }

    onBeforeMount(async () => {
      await Promise.all([
        fetchSession({ session: props.sessionId }), // need session first to get session cameras
      ])
      await Promise.all([ // request at the same time
        fetchSessions({ pageSize: 1, filter: JSON.stringify({ source: session.value.name }) }),
        fetchLeft({
          session: props.sessionId,
          pageSize: -1,
          filter: JSON.stringify({
            $or: [
              { camera: session.value.cameras?.[0] },
              { camera: session.value.cameras?.[0].replaceAll('/', '-') },
            ],
          }),
          orderBy: 'segmentIndex',
        }),
        fetchRight({
          session: props.sessionId,
          pageSize: -1,
          filter: JSON.stringify({
            $or: [
              { camera: session.value.cameras?.[1] },
              { camera: session.value.cameras?.[1].replaceAll('/', '-') },
            ],
          }),
          orderBy: 'segmentIndex',
        }),
      ])
      if (foundSessions.value.length === 0) {
        const response = await api.sessions.sessionsCreateSession({
          body: { ...session, type: AiSessionType.PreAnnotation, source: session.value.name },
        })
        sessionB.value = response.data
      } else {
        sessionB.value = foundSessions.value[0]
      }
      const [, sessionBId] = (sessionB.value?.name ?? '').split('/')
      fetchUnits({ session: sessionBId }) // fetch the units data from sessionBId
    })

    const submittedLeft = computed(() => {
      const submit = itemsLeft.value.map(v => {
        const temp = units.value.filter(u => (u?.videoSegments ?? []).includes(v.name as string))
        return temp
      })
      return submit
    })

    const submittedRight = computed(() => {
      const submit = itemsRight.value.map(v => {
        const temp = units.value.filter(u => (u?.videoSegments ?? []).includes(v.name as string))
        return temp
      })
      return submit
    })

    const getColor = (name:string) => {
      if (colorMap.has(name) === false) {
        // hsl(0 - 360, 20-80%, 50%)
        const h = 0 + Math.random() * (360 - 0)
        const s = 10 + Math.random() * (80 - 10)
        const l = 50
        colorMap.set(name, 'hsl(' + h + ',' + s + '%,' + l + '%)')
        // colorMap.set(name, '#' + Math.floor(Math.random() * 16777215).toString(16))
      }
      return colorMap.get(name)
    }

    watch(submittedLeft, () => {
      // console.log(1)
    })

    watch(submittedRight, () => {
      // console.log(1)
    })

    return {
      review,
      itemsLeft,
      itemsRight,
      session,
      onCheck,
      onSubmit,
      checked,
      submittedLeft,
      submittedRight,
      getColor,
    }
  },
})
