import { Ref } from 'vue'

import { api } from '@/main'
import useGetModel from './generic'
import { AiSession } from '@/api/sessions'

type GetArgs = Parameters<typeof api.sessions.sessionsGetSession>
type UpdateArgs = Parameters<typeof api.sessions.sessionsUpdateSession>
const get = (...args: GetArgs) => api.sessions.sessionsGetSession(...args)
const update = (...args: UpdateArgs) => api.sessions.sessionsUpdateSession(...args)

export default (): {
    readonly item: Ref<AiSession>;
    fetchItem: (...args: GetArgs) => void;
    // eslint-disable-next-line
    fetchByName: (name: string, options?:any) => void;
    readonly error: Ref<boolean>;
    readonly loading: Ref<boolean>;
    clearError: () => void;
} => useGetModel(get, update)
