import { Ref } from 'vue'
import { api } from '@/main'
import useListModel from './generic'
import { AiUnit } from '@/api/units'

type Args = Parameters<typeof api.units.unitsListUnits>
const model = (...args: Args) => api.units.unitsListUnits(...args)

export default (): {
    items: Ref<AiUnit[]>;
    // eslint-disable-next-line
    fetchItems: (...args: Args) => Promise<void>;
    readonly end: Ref<boolean>;
    readonly error: Ref<boolean>;
    clearError: () => void;
    readonly loading: Ref<boolean>;
    // eslint-disable-next-line
    nextPage: () => Promise<void>;
} => useListModel(model)
