import { Ref, ref, readonly } from 'vue'

import { AxiosPromise } from 'axios'

/* eslint-disable @typescript-eslint/no-explicit-any */

type GetMethod<T, R> = (request: R, options: any) => AxiosPromise<T>
type UpdateMethod<T, R> = (request: R, options: any) => AxiosPromise<T>

const useGetModel = function<T, R, U> (method: GetMethod<T, R>, updateMethod: UpdateMethod<T, U>): {
    readonly item: Ref<T>;
    fetchItem: (request: R, options?: any) => Promise<void>;
    fetchByName: (name: string, options?:any) => Promise<void>;
    update: (request: U, options?: any) => Promise<void>;
    readonly error: Ref<boolean>;
    readonly loading: Ref<boolean>;
    clearError: () => void;
} {
  const item = ref<T>()
  const error = ref<boolean>(false)
  const loading = ref<boolean>(false)

  const fetchItem = async (request: R, options: any) => {
    loading.value = true

    try {
      item.value = (await method(request, options)).data
    } catch (err) {
      console.error(err)
      error.value = true
    }

    loading.value = false
  }

  const fetchByName = function (name: string, options?: any) {
    const parts = name.split('/')
    const args = parts.filter((_, i) => !!(i % 2))
    const keys = parts.filter((_, i) => !(i % 2)) as (keyof R)[]
    const request = keys.reduce((acc, k, i) => { return { ...acc, [k]: args[i] } }, {} as R)
    return fetchItem(request, options)
  }

  const update = async (request: U, options?: any) => {
    loading.value = true

    try {
      item.value = (await updateMethod(request, options)).data
    } catch (err) {
      console.error(err)
      error.value = true
    }

    loading.value = false
  }

  const clearError = () => { error.value = false }

  return {
    item: readonly(item) as Ref<T>,
    fetchItem,
    fetchByName,
    update,
    error: readonly(error),
    loading: readonly(loading),
    clearError,
  }
}

export default useGetModel
